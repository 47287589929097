import api from '@/services/api'
import { mapActions, mapState } from 'vuex'

export default {
    name: 'Header',
    methods: {
        ...mapActions('authentication', [
            'signOut'
        ]),
    },
}
