import Vue from 'vue'
import VueRouter from 'vue-router'
import Orders from '@/components/Orders/Orders.vue'
import Detail from '@/components/Detail/Detail.vue'
import Signin from '@/components/Signin/Signin.vue'
import Cards from '@/components/Cards/Cards.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Orders',
    component: Orders,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/inloggen',
    name: 'Signin',
    component: Signin
  },
  {
    path: '/cards',
    name: 'Cards',
    component: Cards
  },
  {
    path: '/:order',
    name: 'Detail',
    component: Detail,
    meta: {
      requiresAuth: true,
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Only check whether there is a jwt token, not if it's valid. This is done when making actual calls to the API.
// But this step prevents making calls to the API when there is not even a token, only when a route has the requiresAuth tag inside the meta object.
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("jwt") == null) {
      next({
        path: "/inloggen",
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
