import { mapActions, mapGetters, mapState } from "vuex"
import Header from '@/components/Header/Header.vue'

export default {
    name: 'Orders',
    components: {
        Header
    },
    data() {
        return {
            search: '',
            years: '',
            events: [],
            filters: {
                finished: "",
                received_by_customer: "",
                payment_status_id: "",
                event_year: "",
                event_name: ""
            }
        }
    },
    methods: {
        ...mapActions('orders', [
            'retrieveOrders',
        ]),
        ...mapActions('products', [
            'retrieveProducts',
        ]),
        ...mapActions('statuses', [
            'retrieveReceiveMethods',
            'retrievePaymentMethods',
            'retrievePaymentStatuses',
            'retrieveEmailStatuses',
        ]),
        detail(orderNumber) {
            this.$router.push({ name: 'Detail', params: { order: orderNumber } })
        },
        setSortingAttribute(attribute) {
            this.$store.commit('orders/setSortAttribute', attribute)
            this.retrieveOrders(false)
        },
        setSearch() {
            this.$store.commit('orders/setSearch', this.search.toLowerCase())
            this.retrieveOrders(false)
        },
        setFilters() {
            this.$store.commit('orders/setFilters', this.filters)
            this.retrieveOrders(false)
        },
        resetSearch() {
            this.search = ""
            this.setSearch()
        },
        loadMore() {
            this.retrieveOrders(true)
        },
    },
    computed: {
        ...mapState({
            orders: state => state.orders.all,
            totalOrders: state => state.orders.totalToPaginate,
            sortAttribute: state => state.orders.sortAttribute,
            searchFromStore: state => state.orders.search,
            loading: state=> state.orders.loading,
            error: state=> state.orders.error,

            paymentStatuses: state => state.statuses.paymentStatuses,
        }),
        ...mapGetters('statuses', [
            'getReceiveMethodById',
            'getPaymentStatusById',
            'getPaymentMethodById',
            'getEmailStatusById',
        ]),
        filterPaymentStatuses() {
            const names = ['Succeeded', 'Failed', 'Open']
            return this.paymentStatuses.filter(status => names.includes(status.name))
        }
    },
    filters: {
        date(datetime) {
            if (datetime) {
                return new Date(datetime).toLocaleString()
            }
        },
    },
    created() {
        this.retrieveOrders()
        this.retrieveProducts()
        this.retrieveReceiveMethods()
        this.retrievePaymentMethods()
        this.retrievePaymentStatuses()
        this.retrieveEmailStatuses()

        const year = new Date().getFullYear()
        this.years = [year - 2, year - 1, year]

        this.events = ["Wervershoof", "Onderdijk"]
    }
}
