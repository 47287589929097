import api from '@/services/api'

const state = () => ({
    all: [],
})

const getters = {
    getProductById: state => id => {
        return state.all.find(item => item.id === id)
    },
}

const mutations = {
    setProducts(state, products) {
        state.all = products
    },
}

const actions = {
    async retrieveProducts({commit}) {
        const response = await api.get('/all-products')
        if (!response.error) commit('setProducts', response)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}