import api from '@/services/api'

const state = () => ({
    receiveMethods: [],
    paymentMethods: [],
    paymentStatuses: [],
    emailStatuses: [],
})

const getters = {
    getReceiveMethodById: state => id => {
        return state.receiveMethods.find(item => item.id === id)
    },
    getPaymentMethodById: state => id => {
        return state.paymentMethods.find(item => item.id === id)
    },
    getPaymentStatusById: state => id => {
        return state.paymentStatuses.find(item => item.id === id)
    },
    getEmailStatusById: state => id => {
        return state.emailStatuses.find(item => item.id === id)
    },
}

const mutations = {
    setReceiveMethods(state, items) {
        state.receiveMethods = items
    },
    setPaymentMethods(state, items) {
        state.paymentMethods = items
    },
    setPaymentStatuses(state, items) {
        state.paymentStatuses = items
    },
    setEmailStatuses(state, items) {
        state.emailStatuses = items
    },
}

const actions = {
    async retrieveReceiveMethods({commit}) {
        const response = await api.get('/receive_methods')
        if (!response.error) commit('setReceiveMethods', response)
    },
    async retrievePaymentMethods({commit}) {
        const response = await api.get('/payment_methods')
        if (!response.error) commit('setPaymentMethods', response)
    },
    async retrievePaymentStatuses({commit}) {
        const response = await api.get('/payment_statuses')
        if (!response.error) commit('setPaymentStatuses', response)
    },
    async retrieveEmailStatuses({commit}) {
        const response = await api.get('/email_statuses')
        if (!response.error) commit('setEmailStatuses', response)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}