import api from '@/services/api'
import router from '../../router'

const state = () => ({
    all: [],
    
    loading: false,
    error: false,
    search: '',
    sortAttribute: 'order_number',
    sortDirection: 'DESC',
    filters: {},

    // Pagination
    page: 0,
    totalToPaginate: 0,
})

const getters = {
    getOrderByNumber: state => number => {
        return state.all.find(item => item.order_number === number)
    },
}

const mutations = {
    setOrders(state, orders) {
        state.all = orders
    },
    setPage(state, page) {
        state.page = page
    },
    setSortAttribute(state, sortAttribute) {
        state.sortDirection = ((state.sortAttribute == sortAttribute) && state.sortDirection !== 'DESC') ? 'DESC' : 'ASC'
        state.sortAttribute = sortAttribute
    },
    setFilters(state, filters) {
        state.filters = filters
    },
    setSearch(state, search) {
        state.search = search
    },
    setTotalToPaginate(state, total) {
        state.totalToPaginate = total
    },
    setLoading(state, status) {
        state.loading = status
    },
    setError(state, status) {
        state.error = status
    },
}

const actions = {
    async retrieveOrders({state, commit, dispatch}, addedPage) {
        if (!addedPage) commit('setLoading', true)
        commit('setError', false)
        
        const page = addedPage ? state.page + 1 : 0
        commit('setPage', page)

        const response = await api.post('/filtered-orders', {
            search: state.search,
            size: 10,
            page: state.page,
            filters: state.filters,
            sort_attribute: state.sortAttribute,
            sort_direction: state.sortDirection
        })

        if (!response.error) {
            const orders = state.page > 0 ? state.all.concat(response.orders) : response.orders

            commit('setOrders', orders)
            commit('setTotalToPaginate', response.total)
        } else {
            commit('setError', true)
        }
        if (!addedPage) commit('setLoading', false)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}