import { mapGetters, mapState } from "vuex"
import api from '@/services/api'
import jsPDF from 'jspdf'
import Header from '@/components/Header/Header.vue'

export default {
    name: 'Detail',
    components: {
        Header
    },
    data() {
        return {
            order: undefined,
            loadingAction: false
        }
    },
    methods: {
        assignOrder() {
            this.order = this.getOrderByNumber(parseInt(this.$route.params.order))
        },
        async confirmPayment() {
            this.$swal.fire({
                title: `Heeft de klant ${this.order.total_formatted} betaald?`,
                text: 'Weet je zeker dat de klant heeft betaald? Zo ja, dan wordt de betaalstatus op succeeded gezet en eventueel de digitale foto\'s e-mail verzonden',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Ja'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.loadingAction = true
                    const response = await (await api.post(`/confirm-payment/${this.order.id}`))
                    this.loadingAction = false

                    if (!response.error) {
                        if (response.warning) {
                            this.$swal.fire({
                                title: 'Gelukt',
                                text: response.warning
                            })
                        } else {
                            this.$swal.fire({
                                title: 'Gelukt',
                                text: 'De order statussen zijn geupdate'
                            })
                        }
                        this.order = response.order
                    } else {
                        console.log(response)
                        this.$swal.fire({
                            title: 'Er is een fout opgetreden',
                            text: 'Check de logs voor eventuele fouten'
                        })
                    }
                }
            })
        },
        async confirmReceived() {
            this.$swal.fire({
                title: `Heeft de klant de bestelling ontvangen?`,
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Ja'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.loadingAction = true
                    const response = await (await api.post(`/confirm-received/${this.order.id}`))
                    this.loadingAction = false
                    if (!response.error) {
                        this.$swal.fire({
                            title: 'Gelukt',
                            text: 'De bestelling is geupdate'
                        })
                        this.order = response
                    } else {
                        console.log(response)
                        this.$swal.fire({
                            title: 'Er is een fout opgetreden',
                            text: 'Check de logs voor eventuele fouten'
                        })
                    }
                }
            })
        },
        async confirmFinished() {
            this.$swal.fire({
                title: `Heb je de order gereed gemaakt?`,
                text: 'Er zal een mail worden verzonden naar de klant dat de bestelling gereed is',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Ja'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.loadingAction = true
                    const response = await (await api.post(`/confirm-finished/${this.order.id}`))
                    this.loadingAction = false
                    if (!response.error) {
                        this.$swal.fire({
                            title: 'Gelukt',
                            text: 'De mail is verzonden en de bestelling geupdate in de database'
                        })
                        this.order = response
                    } else {
                        console.log(response)
                        this.$swal.fire({
                            title: 'Er is een fout opgetreden',
                            text: 'Check de logs voor eventuele fouten'
                        })
                    }
                }
            })
        },
        shippingLabel() {
            this.loadingAction = true
            const doc = new jsPDF('l', 'mm', ['100', '62'])
            doc.setFont(undefined, "bold")
            doc.setFontSize(12)
            doc.text(5, 10, `Order: ${this.order.order_number}`)
            doc.setFont(undefined, "normal")
            doc.setFontSize(20)
            doc.text(5, 25, `${this.order.name}\n${this.order.address}\n${this.order.postcode}  ${this.order.place.toUpperCase()}`, {maxWidth: 90})

            doc.setFontSize(10)
            doc.text(5, 54, `${this.printedPhotosAmount.toString()} ${this.printedPhotosAmount == 1 ? 'foto' : 'foto\'s'}`)
            doc.setFont(undefined, "bold")
            doc.setFontSize(9)
            doc.text(5, 60, "kermis-foto.nl - Handelsweg 17 - 1693 AZ  WERVERSHOOF")

            doc.save()
            this.loadingAction = false
        },
        printDigitalPhotos() {
            this.$swal.fire('Dit moet via de cmd van een laptop, vraag Paul of Dian')
        },
        goTo(photo) {
            if (photo.code) {
                window.open(
                    `https://kermis-foto.nl/wervershoof/2021/foto/${photo.code}`,
                    '_blank'
                )
            }
        }
    },
    computed: {
        ...mapState('orders', [
            'all',
            'loading'
        ]),
        ...mapGetters('orders', [
            'getOrderByNumber',
        ]),
        ...mapGetters('products', [
            'getProductById',
        ]),
        ...mapGetters('statuses', [
            'getReceiveMethodById',
            'getPaymentStatusById',
            'getPaymentMethodById',
            'getEmailStatusById',
        ]),
        orderPaid() {
            const paymentStatus = this.getPaymentStatusById(this.order.payment_status_id)
            if (paymentStatus) {
                const paidStatus = paymentStatus.name == "Succeeded"
                this.order.payment_status_id === paidStatus.id
            }
        },
        printedPhotosAmount() {
            const items = this.order.order_items.filter(item => item.product.name == "Kermis foto")

            if (items.length == 0) return 0

            return items.reduce((total, item) => {
                return total + item.quantity
            }, 0)
        }
    },
    watch: {
        all() {
            // Watching array of orders from vuex, when the array is filled the getter can be fired
            this.assignOrder()
        }
    },
    created() {
        this.assignOrder()
    },
    filters: {
        date(datetime) {
            if (datetime) {
                const date = new Date(datetime)
                const monthNames = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december']

                const month = monthNames[date.getMonth()];
                return `${date.getDate()} ${month} ${date.getFullYear()}, ${date.toLocaleTimeString()}`
            }
        },
        euro(price) {
            const formatter = new Intl.NumberFormat('nl-NL', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2
            });
            return price ? formatter.format(parseFloat(price)) : null;
        },
    }
}
