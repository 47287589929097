import jsPDF from 'jspdf'
import api from '@/services/api'
import Header from '@/components/Header/Header.vue'

export default {
    name: 'Cards',
    components: {
        Header
    },
    data() {
        return {
            layout: [],
            redirects: [],
            barcode: '',
            logo: '',
            logoText: '',
            loading: false,
            code: '3A1',
            year: '',
            event: 'wervershoof',
        }
    },
    methods: {
        createCardsByCode() {
            // Cards.json is created on rails api with rake task rails create:cards, then we fetch the cards.json with all possible cards for kermis whoof 2021
            const url = `https://kermis-foto.nl/${this.event.toLowerCase()}/${this.year}/${this.code}`
            const redirect = this.redirects.find(r => r.url == url)
            if (redirect) {
                this.$swal.fire({
                    title: 'Kaartjes maken',
                    text: `Wil je kaartjes maken voor verwijslink: ${redirect.redirect_url} die verwijst naar: ${redirect.url}`,
                    showCancelButton: true,
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.createCards(this.code, redirect.redirect_url)
                    }
                })
            } else {
                alert(`Redirect met url: ${url} is niet gevonden`)
            }
        },
        async createAllCards() {
            this.$swal.fire({
                title: 'Wil je alle kaartjes maken',
                text: `Let op er zullen 234 bestanden worden gedownload naar je download folder`,
                showCancelButton: true,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    for (let index = 0; index < this.redirects.length; index++) {
                        const element = this.redirects[index];
                        console.log(`Kaartjes maken voor verwijslink: ${element.redirect_url} die verwijst naar: ${element.url}`)
                        const url_arr = element.url.split('/')
                        const code = url_arr[url_arr.length - 1]
                        await this.createCards(code, element.redirect_url)
                    }
                }
            })
            
        },
        async createCards(code, redirectUrl) {
            const doc = new jsPDF('l', 'mm', 'a4')
            //max heigth + x = 32.05
            doc.addImage(this.barcode, 'PNG', 8, 29.5, 22, 25.167)
            doc.setLineWidth(1.0)
            doc.line(15, 15, 21, 15);
            doc.line(15, 15, 15, 21);
            // `https://verwijslink.nl/${redirectId}`
            const base64 = await this.getQrCode(redirectUrl)
            const qrcodeSize = 28
            const cardSize = 61.1
            const maxWidth = 59
            this.layout.elements.forEach((element) => {
                // doc.line(element.x, element.y, element.x + cardSize, element.y)
                // doc.line(element.x, element.y, element.x, element.y + cardSize)
                // doc.line(element.x, element.y + cardSize, element.x + cardSize, element.y + cardSize)
                // doc.line(element.x + cardSize, element.y, element.x + cardSize, element.y + cardSize)

                doc.setFont(undefined, 'normal')
                doc.setFontSize(10)
                const text = 'Scan de QR Code om jouw kermis foto te bestellen!'
                doc.text(text, element.x + ((cardSize-maxWidth)/2) +  maxWidth / 2, element.y + 3, {baseline: 'top', maxWidth: maxWidth, align: 'center'})
                
                // QR CODE
                doc.addImage(`data:image/png;base64, ${base64}`, 'JPEG', element.x + ((cardSize - qrcodeSize) / 2) , element.y + 11, qrcodeSize, qrcodeSize, 'qrcode', 'NONE');
                // URL
                const url = 'kermis-foto.nl'
                doc.setFontSize(8)
                doc.text('of ga naar:', element.x + this.getXOffset(cardSize, doc, 'of ga naar:'), element.y + qrcodeSize + 10, {baseline: 'top'})
                doc.setFontSize(10)
                doc.text(url, element.x + this.getXOffset(cardSize, doc, url), element.y + qrcodeSize + 13, {baseline: 'top'})
                // Logo with text
                const imageWidth = 25
                doc.addImage(this.logoText, 'JPEG', element.x + cardSize - imageWidth - 3, element.y + qrcodeSize + 10, imageWidth, 20.59, 'logo', 'NONE');
                // Block code
                doc.setFont(undefined, 'bold')
                doc.setFontSize(11)
                doc.text('CODE', element.x + 5, element.y + qrcodeSize + 20.5, {baseline: 'top'})
                doc.setFontSize(18)
                doc.text(code, element.x + 5, element.y + qrcodeSize + 25, {baseline: 'top'})
            });
            doc.addPage()
            this.layout.elements.forEach((element) => {
                // Reversing the x positions because of short edge double sided printing
                const x = 297 - element.x - cardSize
                // doc.line(x, element.y, x + cardSize, element.y)
                // doc.line(x, element.y, x, element.y + cardSize)
                // doc.line(x, element.y + cardSize, x + cardSize, element.y + cardSize)
                // doc.line(x + cardSize, element.y, x + cardSize, element.y + cardSize)
                const imageWidth = 44;
                const imageHeight = 50;

                // Background logo
                doc.addImage(this.logo, "JPEG", x + ((cardSize - imageWidth) / 2), element.y + ((cardSize - imageHeight) / 2), imageWidth, imageHeight)
                
                doc.setFont(undefined, 'bold')
                doc.setFontSize(16)
                doc.text('Kermis-foto.nl', x + this.getXOffset(cardSize, doc, 'Kermis-foto.nl'), element.y + 4, {baseline: 'top'})
                
                doc.setFont(undefined, 'normal')
                doc.setFontSize(13)
                doc.text(['1. Scan de QR-code.', '2. Zoek jouw kermis-foto.', '3. Bestel (evt. digitaal).', '4. De bestelling wordt bezorgd.'], x + 3, element.y + 19, {baseline: 'top', maxWidth: 58})
                doc.setFontSize(6)
                doc.text('De foto\'s worden pas gedrukt op moment van bestelling, om papierverspilling tegen te gaan.',  x + ((cardSize-maxWidth)/2) +  maxWidth / 2, element.y + cardSize - 3.5, {baseline: 'bottom', maxWidth: maxWidth, align: 'center'})
            });
            doc.save(`${code}.pdf`)
        },
        getXOffset(cardSize, doc, element) {
            return (cardSize - doc.getTextDimensions(element).w) / 2
        },
        async getQrCode(url) {
            return this.post('/qrcode', {url: url})
        },
        async post(url, data) {
            const api = process.env.NODE_ENV == 'development' ? 'http://localhost:3000' : 'https://api.kermis-foto.nl'
            try {
                return await (await fetch(api + url, {
                    method: 'POST',
                    credentials: "include",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                })).text()
            } catch (error) {
                console.log(error)
            }
        }
    },
    async created() {
        this.layout = await (await fetch(window.location.origin + '/templates/cards.json')).json()
        this.barcode = await (await fetch(window.location.origin + '/templates/barcode')).text()
        this.logo = await (await fetch(window.location.origin + '/templates/logo')).text()
        this.logoText = await (await fetch(window.location.origin + '/templates/logo_text')).text()
        this.redirects = await api.get('/cards.json')
        this.year = new Date().getFullYear()
    }
}
